import React, { Fragment, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
// Material-UI imports
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// Custom imports
import {
    userIsAuthenticated,
    fetchApi,
    accessIsAllowed,
    logOut
} from "@globalholdings/ui-common-functions";
import {
    Error,
    SessionModal,
    SessionTimer
    //Navigation
} from "@globalholdings/ui-common-components";
import Navigation from "../../components/Navigation";
import { PermissionsContext } from "../../context/Permissions";
import { RefreshFiltersContext } from "../../context/RefreshFilters";
import { CountsContext } from "../../context/Counts";

const Layout = props => {
    const classes = useStyles();
    const [currentPermissions, setCurrentPermissions] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState(
        sessionStorage.getItem("firstName")
    );
    const [lastName, setLastName] = useState(sessionStorage.getItem("lastName"));
    const [links, setLinks] = useState([]);
    const [counts, setCounts] = useState({});
    const [sessionModalOpen, setSessionModalOpen] = useState(false);

    const updateCountsCallbacks = {
        updateExceptions: exceptionsApi => {
            fetchExceptionsCount(exceptionsApi);
            fetchDeferralsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Deferred/Count`
            );
            fetchEscalationsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Escalated/Count`
            );
        },
        updateDeferrals: deferralsApi => {
            fetchDeferralsCount(deferralsApi);
            fetchExceptionsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Next/Count`
            );
            fetchEscalationsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Escalated/Count`
            );
        },
        updateEscalations: escalationsApi => {
            fetchEscalationsCount(escalationsApi);
            fetchDeferralsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Deferred/Count`
            );
            fetchExceptionsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Next/Count`
            );
        }
    };

    const handleOpenSessionModal = () => {
        setSessionModalOpen(true);
    };

    const handleCloseSessionModal = () => {
        setSessionModalOpen(false);
    };

    const fetchExceptionsCount = useCallback(
        api => {
            if (
                userIsAuthenticated() &&
                accessIsAllowed(currentPermissions, "ERMA.UI", "R")
            ) {
                fetchApi("GET", api, {}, false)
                    .then(response => {
                        if (response.status === 200) {
                            setCounts(counts => {
                                return {
                                    ...counts,
                                    0: response.data
                                };
                            });
                        } else {
                            setCounts(counts => {
                                return {
                                    ...counts,
                                    0: 0
                                };
                            });
                        }
                    })
                    .catch(() => {
                        setCounts(counts => {
                            return {
                                ...counts,
                                0: 0
                            };
                        });
                    });
            }
        },
        [currentPermissions]
    );

    const fetchDeferralsCount = useCallback(
        api => {
            if (
                userIsAuthenticated() &&
                accessIsAllowed(currentPermissions, "ERMA.UI", "R")
            ) {
                fetchApi("GET", api, {}, false)
                    .then(response => {
                        if (response.status === 200) {
                            setCounts(counts => {
                                return {
                                    ...counts,
                                    1: response.data
                                };
                            });
                        } else {
                            setCounts(counts => {
                                return {
                                    ...counts,
                                    1: 0
                                };
                            });
                        }
                    })
                    .catch(error => {
                        setCounts(counts => {
                            return {
                                ...counts,
                                1: 0
                            };
                        });
                    });
            }
        },
        [currentPermissions]
    );

    const fetchEscalationsCount = useCallback(
        api => {
            if (
                userIsAuthenticated() &&
                accessIsAllowed(currentPermissions, "ERMA.UI.Escalations", "R")
            ) {
                fetchApi("GET", api, {}, false)
                    .then(response => {
                        if (response.status === 200) {
                            setCounts(counts => {
                                return {
                                    ...counts,
                                    2: response.data
                                };
                            });
                        } else {
                            setCounts(counts => {
                                return {
                                    ...counts,
                                    2: 0
                                };
                            });
                        }
                    })
                    .catch(error => {
                        setCounts(counts => {
                            return {
                                ...counts,
                                2: 0
                            };
                        });
                    });
            }
        },
        [currentPermissions]
    );

    const refreshFilters = {
        timeStamp: moment().format("MM/DD hh:mm A"),
        func: () => {
            fetchExceptionsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Next/Count`
            );
            fetchDeferralsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Deferred/Count`
            );
            fetchEscalationsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Escalated/Count`
            );
        }
    };

    useEffect(() => {
        if (userIsAuthenticated()) {
            const fetchUserName = () => {
                const api = `
        ${window.CONFIG.COMMON_API_URL}/users/user`;
                fetchApi("GET", api).then(response => {
                    if (response.status === 200) {
                        sessionStorage.setItem("firstName", response.data.firstName);
                        sessionStorage.setItem("lastName", response.data.lastName);
                        setFirstName(sessionStorage.firstName);
                        setLastName(sessionStorage.lastName);
                        setLoading(false);
                    } else {
                        setFirstName("");
                        setLastName("");
                    }
                });
            };
            if (
                !sessionStorage.getItem("firstName") &&
                !sessionStorage.getItem("lastName")
            ) {
                fetchUserName();
            }
        }
    }, [firstName, lastName]);

    useEffect(() => {
        if (currentPermissions.length === 0 && userIsAuthenticated()) {
            const permissionsUrl = `${window.CONFIG.AUTH_API_URL}/auth/securedcomponents`;
            fetchApi("GET", permissionsUrl)
                .then(response => {
                    if (response.status === 200) {
                        setCurrentPermissions(response.data);
                    } else {
                        throw new Error(`Error Code ${response.status}`);
                    }
                })
                .catch(error => {
                    setError(true);
                });
        }
    }, [currentPermissions.length]);

    useEffect(() => {
        fetchExceptionsCount(
            `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Next/Count`
        );
        fetchDeferralsCount(
            `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Deferred/Count`
        );
        fetchEscalationsCount(
            `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Escalated/Count`
        );
    }, [fetchDeferralsCount, fetchEscalationsCount, fetchExceptionsCount]);

    useEffect(() => {
        if (
            accessIsAllowed(currentPermissions, "ERMA.UI", "R") &&
            accessIsAllowed(currentPermissions, "ERMA.UI.Escalations", "R")
        ) {
            setLinks([
                {
                    order: 0,
                    title: "Exceptions",
                    href: "/exceptions"
                },
                {
                    order: 1,
                    title: "Deferrals",
                    href: "/deferrals"
                },
                {
                    order: 2,
                    title: "Escalations",
                    href: "/escalations"
                },
                { order: 3, title: "History", href: "/history" }
            ]);
        } else {
            setLinks([
                {
                    order: 0,
                    title: "Exceptions",
                    href: "/exceptions"
                },
                {
                    order: 1,
                    title: "Deferrals",
                    href: "/deferrals"
                },
                { order: 3, title: "History", href: "/history" }
            ]);
        }
    }, [currentPermissions]);

    useEffect(() => {
        props.history.listen(() => {
            fetchExceptionsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Next/Count`
            );
            fetchDeferralsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Deferred/Count`
            );
            fetchEscalationsCount(
                `${window.CONFIG.APP_API_URL}/phonepay/exceptionresponses/Escalated/Count`
            );
        });
    }, [
        currentPermissions,
        fetchDeferralsCount,
        fetchEscalationsCount,
        fetchExceptionsCount,
        props.history
    ]);

    useEffect(() => {
        if (links.length > 2) {
            setLoading(false);
        }
    }, [links.length]);

    if (
        !loading &&
        !error &&
        userIsAuthenticated() &&
        window.location.pathname !== "/"
    ) {
        return (
            <PermissionsContext.Provider value={currentPermissions}>
                <RefreshFiltersContext.Provider value={refreshFilters}>
                    <CountsContext.Provider value={updateCountsCallbacks}>
                        <Navigation
                            brandMarkLink="/exceptions"
                            appName="ERMA"
                            firstName={firstName}
                            lastName={lastName}
                            logOutPath={`${window.CONFIG.AUTH_UI_URL}/?referrer=UI.PhonePay`}
                            links={links}
                            counts={counts}
                        />
                        <SessionTimer
                            sessionTime={20}
                            sessionCallback={handleOpenSessionModal}
                        />
                        <SessionModal
                            open={sessionModalOpen}
                            counter={120}
                            timeOutFunction={() => {
                                logOut(`${window.CONFIG.AUTH_UI_URL}/?referrer=UI.PhonePay`);
                            }}
                            handleModalAction={handleCloseSessionModal}
                        />
                        <div className={classes.contentContainer}>{props.children}</div>
                    </CountsContext.Provider>
                </RefreshFiltersContext.Provider>
            </PermissionsContext.Provider>
        );
    } else {
        return <Fragment>{props.children}</Fragment>;
    }
};

const useStyles = makeStyles(theme => ({
    contentContainer: {
        margin: theme.spacing(1.5, 2)
    }
}));

export default withRouter(Layout);
