import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
// Custom imports
import { createResponseInterceptor } from "@globalholdings/ui-common-functions";
import { Loading, LoginRedirect } from "@globalholdings/ui-common-components";
import Layout from "../../containers/Layout";
import PrivateRoute from "../../components/PrivateRoute";

const Exceptions = lazy(() => import("../Exceptions"));
const Deferrals = lazy(() => import("../Deferrals"));
const Escalations = lazy(() => import("../Escalations"));
const History = lazy(() => import("../History"));

export default function App() {
  createResponseInterceptor(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_CLIENT_SECRET
  );
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute
              exact
              path="/exceptions"
              component={() => <Exceptions />}
            />
            <PrivateRoute
              exact
              path="/deferrals"
              component={() => <Deferrals />}
            />
            <PrivateRoute
              exact
              path="/escalations"
              component={() => <Escalations />}
            />
            <PrivateRoute exact path="/history" component={() => <History />} />
            <Route
              exact
              path="/"
              component={() => (
                <LoginRedirect
                  authPath={"/exceptions"}
                  loadingComponent={() => <Loading />}
                />
              )}
            />
            <Redirect to="/" />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
}
