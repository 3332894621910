import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
// Material-UI imports
import {
  Typography,
  Button,
  Paper,
  Hidden,
  Drawer,
  Badge,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
// Custom imports
import { logOut } from "@globalholdings/ui-common-functions";
import UserIcon from "./iconUser.svg";
import BrandMark from "./brandMark.svg";
import AppPicker from "@globalholdings/ui-common-components/src/components/AppPicker";

export default function Navigation(props) {
  const classes = useStyles();
  const {
    brandMarkLink,
    appName,
    links,
    counts,
    firstName,
    lastName,
    logOutPath,
  } = props;

  const [open, setOpen] = useState(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <div className={classes.navigationContainer}>
      <Paper id="appHeader" square elevation={1} className={classes.appHeader}>
        <div className={classes.headerLeft}>
          <NavLink className={classes.branding} to={brandMarkLink}>
            <div id="logoContainer" className={classes.logoContainer}>
              <img src={BrandMark} alt="Global Holdings" width="80" />
            </div>
            <Typography component="h1" variant="h1" className={classes.appName}>
              {appName}
            </Typography>
          </NavLink>
          <Hidden smDown>
            <div className={classes.menu}>
              {links.length > 0 &&
                links
                  .sort((a, b) => {
                    return a.order > b.order ? 1 : -1;
                  })
                  .map((link) => {
                    return (
                      <Fragment key={`desktop-${link.title}`}>
                        <Badge
                          className={classes.badge}
                          badgeContent={counts[link.order]}
                          color="primary"
                          max={999}
                          key={`badge-${link.title}`}
                        >
                          <NavLink className={classes.menuLink} to={link.href}>
                            {link.title}
                          </NavLink>
                        </Badge>
                      </Fragment>
                    );
                  })}
            </div>
          </Hidden>
        </div>
        <div className={classes.headerRight}>
          <AppPicker IsCompact={true} />
          <Hidden smDown>
            <img
              src={UserIcon}
              width="32"
              className={classes.userIcon}
              id="userIcon"
              alt="User Icon"
            />
            <Typography
              component="p"
              variant="body1"
              className={classes.userName}
            >
              {sessionStorage.getItem("firstName") || firstName}{" "}
              {sessionStorage.getItem("lastName") || lastName}
            </Typography>
            <Button
              onClick={() => {
                logOut(logOutPath);
              }}
              color="primary"
              className={classes.logOut}
            >
              Logout
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Button
              color="primary"
              aria-label="open drawer"
              aria-controls="navigation-menu"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuOutlinedIcon />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Drawer
              className={classes.drawer}
              anchor="left"
              open={open}
              onClose={handleDrawerClose}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <Button
                  aria-controls="navigation-menu"
                  aria-label="close navigation menu"
                  className={classes.menuButton}
                  color="primary"
                  onClick={handleDrawerClose}
                >
                  <ChevronLeftOutlinedIcon />
                </Button>
              </div>
              <div>
                {links.length > 0 &&
                  links
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((link) => {
                      return (
                        <div key={`mobile-${link.title}`}>
                          <Badge
                            className={classes.mobileBadge}
                            badgeContent={link.count}
                            color="primary"
                            max={999}
                            key={`badge-${link.title}`}
                          >
                            <NavLink
                              className={`${classes.menuLink} ${classes.mobileMenuLink}`}
                              to={link.href}
                              onClick={handleDrawerClose}
                            >
                              {link.title}
                            </NavLink>
                          </Badge>
                        </div>
                      );
                    })}
                <Button
                  onClick={() => {
                    logOut(logOutPath);
                  }}
                  color="primary"
                  className={classes.mobileLogOut}
                >
                  Logout
                </Button>
              </div>
            </Drawer>
          </Hidden>
        </div>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  navigationContainer: {
    flex: "0 0 100%",
    height: "80px",
  },
  appHeader: {
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: theme.spacing(10),
    position: "fixed",
    zIndex: 999,
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
  },
  branding: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  logoContainer: {
    display: "flex",
    height: theme.spacing(10),
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    "& img": {
      width: theme.spacing(4),
      padding: theme.spacing(2, 3),
      "& .st0": {
        fill: "#fff !important",
        stroke: "#fff !important",
      },
    },
  },
  appName: {
    margin: theme.spacing(0, 4, 0, 2),
    height: 40,
    display: "flex",
    alignItems: "center",
    color: "#333",
    fontSize: "1rem",
    fontWeight: 400,
    "@media (min-width:720px)": {
      fontSize: "1.6rem",
    },
  },
  menu: {
    display: "flex",
  },
  menuLink: {
    borderRadius: 0,
    padding: theme.spacing(1, 3),
    height: theme.spacing(7.5),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    borderBottom: `${theme.spacing(0.5)}px solid transparent`,
    "&.active": {
      color: theme.palette.primary.main,
      borderBottom: `${theme.spacing(0.5)}px solid ${
        theme.palette.primary.main
      }`,
    },
  },
  mobileMenuLink: {
    width: "100%",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "fit-content",
  },
  userIcon: {
    marginRight: theme.spacing(1),
  },
  userName: {
    display: "none",
    "@media (min-width: 720px)": {
      display: "flex",
    },
  },
  logOut: {
    marginLeft: theme.spacing(2),
    fontSize: "1rem",
    textTransform: "none",
  },
  mobileLogOut: {
    width: "100%",
    fontSize: "1rem",
    textTransform: "none",
    textAlign: "center",
  },
  drawer: {
    width: 200,
  },
  drawerHeader: {
    borderBottom: "1px solid #eee",
  },
  drawerPaper: {
    width: 280,
  },
  badge: {
    "& span": {
      top: theme.spacing(3),
      right: theme.spacing(1),
    },
  },
  mobileBadge: {
    width: "100%",
    "& .MuiBadge-badge": {
      top: theme.spacing(2),
      right: theme.spacing(4),
    },
  },
}));
